<template>
  <div class="bk-page" id="goodsEdit">
    <Spin class="bk-loading" fix v-if="loading">
      <div>
        <Icon type="ios-loading"></Icon>
        <div>Loading</div>
      </div>
    </Spin>
    <Spin class="bk-failed" fix v-if="failed">
      <div @click="req">
        <Icon type="md-refresh"></Icon>
        <p>Refresh</p>
      </div>
    </Spin>
    <div class="bk-page-scroll">
      <div class="flex" style="align-items: flex-start">
        <Card dis-hover>
          <Form
            :label-width="120"
            :model="formd"
            :rules="rules"
            @submit.native.prevent
            inline
            ref="form"
          >
            <FormItem label="委托单：" prop="OrderId" required>
              <Input
                :value="formd.OrderCode"
                @on-clear="unPickOrder"
                clearable
                readonly
                v-if="ABLE"
              >
                <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
              </Input>
              <div v-else>{{formd.OrderCode}}</div>
            </FormItem>
            <FormItem label="测试项目：" prop="ItemNames" required>
              <Input placeholder="多项目逗号隔开" v-if="ABLE" v-model="formd.ItemNames" />
              <div v-else>{{formd.ItemNames}}</div>
            </FormItem>
            <FormItem label="客户：">
              <Input :value="formd.ClientName" disabled v-if="ABLE" />
              <div v-else>{{formd.ClientName}}</div>
            </FormItem>
            <FormItem label="类型：" prop="SubType" required>
              <Select v-if="ABLE" v-model="formd.SubType">
                <Option :value="1">样品</Option>
                <Option :value="99">其他物料</Option>
              </Select>
              <div v-else>{{formd.SubType}}</div>
            </FormItem>
            <FormItem label="内部编号：" prop="GoodsNo" required>
              <Input v-if="ABLE" v-model="formd.GoodsNo" placeholder="默认系统生成样品编号" />
              <div v-else>{{formd.GoodsNo}}</div>
            </FormItem>
            <FormItem label="原编号：">
              <Input v-if="ABLE" v-model="formd.Code" />
              <div v-else>{{formd.Code}}</div>
            </FormItem>
            <FormItem label="物料名称：" prop="Name" required>
              <Input v-if="ABLE" v-model="formd.Name" />
              <div v-else>{{formd.Name}}</div>
            </FormItem>
            <FormItem label="收样日期：" prop="ReceiveDate" required>
              <DatePicker v-if="ABLE" v-model="formd.ReceiveDate" />
              <div v-else>{{formd.ReceiveDate}}</div>
            </FormItem>
            <FormItem label="规格型号：">
              <Input v-if="ABLE" v-model="formd.Specs" />
              <div v-else>{{formd.Specs}}</div>
            </FormItem>
            <FormItem label="电芯型号：">
              <Input v-if="ABLE" v-model="formd.CellMode" />
              <div v-else>{{formd.CellMode}}</div>
            </FormItem>
            <FormItem label="送检状态：">
              <Input v-if="ABLE" v-model="formd.State" />
              <div v-else>{{formd.State}}</div>
            </FormItem>
            <FormItem label="标称容量：">
              <Input v-if="ABLE" v-model="formd.NominalCapacity" />
              <div v-else>{{formd.NominalCapacity}}</div>
            </FormItem>
            <FormItem label="电芯材料体系：">
              <Input v-if="ABLE" v-model="formd.CellMaterialSystem" />
              <div v-else>{{formd.CellMaterialSystem}}</div>
            </FormItem>
            <FormItem label="组合形式：">
              <Input v-if="ABLE" v-model="formd.CombiningForm" />
              <div v-else>{{formd.CombiningForm}}</div>
            </FormItem>
            <FormItem label="电池类别：">
              <Input v-if="ABLE" v-model="formd.BatteryCategory" />
              <div v-else>{{formd.BatteryCategory}}</div>
            </FormItem>
            <FormItem label="当前SOC：">
              <Input v-if="ABLE" v-model="formd.Soc" />
              <div v-else>{{formd.Soc}}</div>
            </FormItem>
            <FormItem label="额度电压：">
              <Input v-if="ABLE" v-model="formd.RatedVoltage" />
              <div v-else>{{formd.RatedVoltage}}</div>
            </FormItem>
            <FormItem label="工作电压：">
              <Input v-if="ABLE" v-model="formd.WorkingVolt" />
              <div v-else>{{formd.WorkingVolt}}</div>
            </FormItem>
            <FormItem label="充电上限电压：">
              <Input v-if="ABLE" v-model="formd.UpperVoltageLimit" />
              <div v-else>{{formd.UpperVoltageLimit}}</div>
            </FormItem>
            <FormItem label="放电下限电压：">
              <Input v-if="ABLE" v-model="formd.LowerVoltageLimit" />
              <div v-else>{{formd.LowerVoltageLimit}}</div>
            </FormItem>
            <FormItem label="样品尺寸：">
              <Input placeholder="长*宽*高" v-if="ABLE" v-model="formd.Size" />
              <div v-else>{{formd.Size}}</div>
            </FormItem>
            <FormItem label="样品重量：">
              <Input v-if="ABLE" v-model="formd.Weight" />
              <div v-else>{{formd.Weight}}</div>
            </FormItem>
            <FormItem label="壳体极性：">
              <Select v-if="ABLE" v-model="formd.ShellPolarity">
                <Option label="带电" value="带电" />
                <Option label="不带电" value="不带电" />
              </Select>
              <div v-else>{{formd.ShellPolarity}}</div>
            </FormItem>
            <FormItem label="商标：">
              <Input v-if="ABLE" v-model="formd.Brand" />
              <div v-else>{{formd.Brand}}</div>
            </FormItem>
            <FormItem label="生产日期：">
              <DatePicker format="yyyy-MM-dd" v-if="ABLE" v-model="formd.DateOfManufacture"></DatePicker>
              <div v-else>{{formd.DateOfManufacture}}</div>
            </FormItem>
            <FormItem label="生产厂家：">
              <Input v-if="ABLE" v-model="formd.Manufactor" />
              <div v-else>{{formd.Manufactor}}</div>
            </FormItem>
            <FormItem label="电压：">
              <Input v-if="ABLE" v-model="formd.Voltage" />
              <div v-else>{{formd.Voltage}}</div>
            </FormItem>
            <FormItem label="内阻：">
              <Input v-if="ABLE" v-model="formd.InternalResistance" />
              <div v-else>{{formd.InternalResistance}}</div>
            </FormItem>
            <FormItem label="外观：">
              <Input v-if="ABLE" v-model="formd.Exterior" />
              <div v-else>{{formd.Exterior}}</div>
            </FormItem>
            <FormItem label="气味：">
              <Input v-if="ABLE" v-model="formd.Smell" />
              <div v-else>{{formd.Smell}}</div>
            </FormItem>
            <FormItem label="测试阶段：">
              <Input v-if="ABLE" v-model="formd.TestStage" />
              <div v-else>{{formd.TestStage}}</div>
            </FormItem>
            <FormItem class="long" label="描述：" v-show="id > 0">
              <div>{{ formd.StockDescription }}</div>
            </FormItem>
            <FormItem class="long" label="其它情况说明：">
              <Input type="textarea" v-if="ABLE" v-model="formd.ClientOtherDescription" />
              <div v-else>{{formd.ClientOtherDescription}}</div>
            </FormItem>
            <FormItem class="long" label="备注：">
              <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
              <div v-else>{{formd.Remark}}</div>
            </FormItem>
            <FormItem class="long" label="图片：">
              <div class="attas">
                <input
                  @change="onFiles"
                  accept="image/*"
                  multiple
                  ref="attasInput"
                  style="display: none"
                  type="file"
                />
                <div
                  @click="
                    () => {
                      $refs.attasInput.click();
                    }
                  "
                  class="bk-add-file"
                  style="margin-right: 10px"
                  v-if="ABLE && $refs.attasInput"
                >
                  <Icon color="#808695" size="20" type="md-add"></Icon>
                </div>
                <div :key="`img-${i}`" class="atta" v-for="(img, i) in imageList">
                  <img :src="img" @click="viewImg(i)" alt />
                  <Icon
                    @click="removeImg(i)"
                    class="icon-remove"
                    color="#ed4014"
                    size="20"
                    type="md-close-circle"
                    v-if="ABLE"
                  />
                </div>
              </div>
            </FormItem>
          </Form>
        </Card>
        <Card dis-hover style="margin-left:10px" v-if="ABLE">
          <Button :loading="posting" @click="ok" type="primary">保存</Button>
        </Card>
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-trader @on-ok="onPickTrader" ref="PickTrader" type="1" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickTrader from "../../components/PickTrader.vue";
export default {
  components: { PickOrder, PickTrader },
  data() {
    return {
      ABLE: false,
      id: 0,
      formd: { Type: 1 },
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        ItemNames: [{ required: true, message: " " }],
        SubType: [{ required: true, type: "number", message: " " }],
        GoodsNo: [{ required: true, message: " " }],
        Name: [{ required: true, message: " " }],
        GoodsNo: [
          {
            validator: (rule, value, callback) => {
              if (this.formd.SubType == 1) {
                this.formd.GoodsNo ? callback() : callback(new Error(" "));
              } else {
                callback();
              }
            },
          },
        ],
        ReceiveDate: [
          {
            validator: (rule, value, callback) => {
              this.formd.ReceiveDate ? callback() : callback(new Error(" "));
            },
          },
        ],
      },
      loading: false,
      failed: false,
      posting: false,
      orderItems: [],
      revs: [],
      imageList: [],
      imageList_: [],
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      this.ABLE = this.$able.map.WmsGoodsSave;
      this.id = this.$route.params.id || 0;
      // let tabs = that.$util.session("tabs")
      // if (tabs) { tabs = JSON.parse(tabs) }
      if (this.id) {
        this.req();
      } else {
        this.clear();
      }
    },
    clear() {
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = { Type: 1 };
      this.id = 0;
      this.imageList = [];
      this.imageList_ = [];
    },
    req() {
      this.$refs.form.resetFields();
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/WmsGoods/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            let a = [];
            if (this.formd.Img1) {
              a.push(this.formd.Img1);
            }
            if (this.formd.Img2) {
              a.push(this.formd.Img2);
            }
            if (this.formd.Img3) {
              a.push(this.formd.Img3);
            }
            if (this.formd.Img4) {
              a.push(this.formd.Img4);
            }
            if (this.formd.Img5) {
              a.push(this.formd.Img5);
            }
            this.imageList = a;
            this.imageList_ = a.concat([]);
            // if (this.formd.OrderId) {
            //   this.reqItems();
            //   this.reqRevs();
            // }
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      let formdata = new FormData();
      for (let key in this.formd) {
        if (key == "ReceiveDate") {
          if (
            this.formd.ReceiveDate != null &&
            typeof this.formd.ReceiveDate == "object"
          ) {
            formdata.append(
              "ReceiveDate",
              this.formd.ReceiveDate.format("yyyy-MM-dd")
            );
          } else {
            formdata.append(key, this.formd[key] || "");
          }
        } else if (key == "DateOfManufacture") {
          // 生产日期
          if (
            this.formd.DateOfManufacture != null &&
            typeof this.formd.DateOfManufacture == "object"
          ) {
            formdata.append(
              "DateOfManufacture",
              this.formd.DateOfManufacture.format("yyyy-MM-dd")
            );
          } else {
            formdata.append(key, this.formd[key] || "");
          }
        } else if (
          ["Img1", "Img2", "Img3", "Img4", "Img5"].indexOf(key) != -1
        ) {
          continue;
        } else {
          formdata.append(key, this.formd[key] || "");
        }
      }
      console.log(formdata);
      let obj = {};
      for (let a of this.imageList_) {
        if (typeof a == "string") {
          if (obj.Img1 == undefined) {
            formdata.append("Img1", a);
            obj.Img1 = true;
          } else if (obj.Img2 == undefined) {
            formdata.append("Img2", a);
            obj.Img2 = true;
          } else if (obj.Img3 == undefined) {
            formdata.append("Img3", a);
            obj.Img3 = true;
          } else if (obj.Img4 == undefined) {
            formdata.append("Img4", a);
            obj.Img4 = true;
          } else if (obj.Img5 == undefined) {
            formdata.append("Img5", a);
            obj.Img5 = true;
          }
        } else {
          formdata.append("File", a);
        }
      }
      console.log(formdata);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.posting = true;
      this.$axios
        .post("/WmsGoodsEx/SaveGoods", formdata, config)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.id > 0 ? this.req() : this.clear();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
        this.$set(this.formd, "ClientName", lst[0].ClientName);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
      this.$set(this.formd, "ClientName");
      this.$set(this.formd, "GoodsNo", "");
      this.orderItems = [];
      this.formd.ItemId = 0;
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    // 物料接收清单
    reqRevs() {
      this.$axios
        .get("/Api/BtmsBomReceive/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.formd.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.revs = res.data.DataSet;
          }
        });
    },
    // 选择客户
    toPickTrader() {
      let def = [];
      if (this.formd.ClientId) {
        def = [
          {
            Id: this.formd.ClientId,
            UserName: this.formd.ClientName,
          },
        ];
      }
      this.$refs.PickTrader.open(def);
    },
    onPickTrader(lst) {
      if (lst[0]) {
        this.formd.ClientId = lst[0].Id;
        this.$set(this.formd, "ClientName", lst[0].Name);
      } else {
        this.formd.ClientId = "";
        this.$set(this.formd, "ClientName", "");
      }
    },
    unPickTrader() {
      this.formd.ClientId = "";
      this.$set(this.formd, "ClientName", "");
    },
    // 图片
    onFiles(e) {
      if (e.target.files.length > 0 && this.imageList_.length < 5) {
        for (let i = 0; i < e.target.files.length; i++) {
          if (this.imageList_.length < 5) {
            this.imageList.push(this.$util.file2url(e.target.files[i]));
            this.imageList_.push(e.target.files[i]);
          } else {
            break;
          }
        }
      }
      e.target.value = "";
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
    removeImg(i) {
      this.imageList.splice(i, 1);
      this.imageList_.splice(i, 1);
    },
  },
};
</script>
<style lang="less">
#goodsEdit {
  .ivu-form {
    width: 600px;
  }
  .ivu-form-item {
    width: 300px;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 600px;
  }
  .bk-add-file {
    background: #fff;
  }
  .attas {
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
}
</style>